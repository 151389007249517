import React from 'react';

function App() {
    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        window.location = `https://hej.boksnok.se/ge-30-dagar-fa-30-dagar?code=${code}&utm_source=mail&utm_medium=organic-email&utm_campaign=se-invite-friends-2022-kompis&utm_content=30-days-free-trial&utm_term=new-trials`;
    }, []);
    return <div / > ;
}

export default App;